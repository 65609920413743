import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import { useSelector, useDispatch } from 'react-redux'
import { selectPersonDetails, selectCurrentMembership } from 'modules/admin/selectors'
import { Api } from 'typescript-fetch-api'
import { chooseMembership } from 'modules/admin/actions'
import MyNavLink from 'modules/routing/components/MyNavLink'
import { useCanManage, useCanView } from 'modules/admin/hooks'
import logoImg from 'modules/frontend/img/umbrella.png'
import { selectKnowledgeLibrary } from 'modules/knowledgelibrary/selectors'
import { selectLoggedIn } from 'modules/auth/selectors'

export interface Props {
	breadcrumbLink?: string
	breadcrumbText?: string
}

export default function Header(props: Props) {
	const signedIn = useSelector(selectLoggedIn)
	const currentAccount = useSelector(selectCurrentMembership)
	const personDetails = useSelector(selectPersonDetails)
	const [isPopoverOpen, setPopoverOpen] = useState(false)
	const history = useHistory()
	const dispatch = useDispatch()
	const canView = useCanView()
	const knowledgeLibrary = useSelector(selectKnowledgeLibrary)
	const adminMemberships = personDetails && personDetails.memberships.filter(m => m.role === Api.PersonDetails.Membership.RoleEnum.Admin)
	const canManageCurrentAccount = useCanManage()
	const loggedIn = useSelector(selectLoggedIn)

	const hasMenuItems = ((
		currentAccount
	) || (
		knowledgeLibrary
	))

	function doLogout(evt: React.FormEvent) {
		evt.preventDefault()
		history.replace('/auth/sign-out')
	}

	function changeMembership(membership: Api.PersonDetails.Membership) {
		dispatch(chooseMembership(membership))
	}

	return (
		<>
			<header className="site-header">
				<div className="width-limit -site">
					<div className="site-logo"><Link to="/" className="link"><img src={logoImg} alt="Umbrella"/></Link></div>
					{
						hasMenuItems &&
						<input type="checkbox" className="toggle" id="main-nav-menustate" />
					}
					<div className="site-nav _no-print">
						<ul className="navitems">
							{
								currentAccount && currentAccount.role === Api.PersonDetails.Membership.RoleEnum.Admin ? (
									<>
										<MyNavLink className="navitem -dashboard" activeClassName="-selected" to="/admin" exact={true}>Dashboard</MyNavLink>
										{canView && 
											<>
												<MyNavLink className="navitem" activeClassName="-selected" to="/admin/survey">Surveys</MyNavLink>
												<MyNavLink className="navitem" activeClassName="-selected" to="/admin/participants">Participants</MyNavLink>
											</>
										}
										<MyNavLink className="navitem" activeClassName="-selected" to="/admin/reporting">Reporting</MyNavLink>
									</>
								) : loggedIn ? (
									<MyNavLink className="navitem" activeClassName="-selected" to="/p/">Surveys</MyNavLink>
								) : null
							}
							{
								knowledgeLibrary && (
									<>
										<MyNavLink className="navitem" activeClassName="-selected" to="/knowledge-library">Knowledge Library</MyNavLink>
									</>
								)
							}
						</ul>
					</div>
					{
						signedIn &&
						<div className="account-menu _no-print">
							<Popover 
								isOpen={isPopoverOpen}
								position={'bottom'}
								transitionDuration={0.2}
								content={({ position, targetRect, popoverRect }) => (
									<ArrowContainer
										position={position}
										targetRect={targetRect}
										popoverRect={popoverRect}
										arrowColor={'white'}
										arrowSize={10}
										arrowStyle={{ opacity: 1 }}
									>
										<div className="popup-menu -account">
											<ul className="menuitems">
												{personDetails && personDetails.name ? (
													<li className="menuitem -signedin">Signed in as {personDetails?.name}</li>
												) : (
													null
												)}
												{currentAccount && canManageCurrentAccount &&
												<li className="menuitem"><Link to="/account/settings" className="link">Organisation settings</Link> </li>
												}
												{personDetails && 
												<li className="menuitem"><Link to="/account/your-profile" className="link">Your profile</Link> </li>
												}
												<li className="menuitem"><Link to="/p/" className="link">Your survey results</Link> </li>
												{
													knowledgeLibrary && (
														<li className="menuitem"><Link to="/knowledge-library">Knowledge Library</Link> </li>
													)
												}
												{personDetails && personDetails.superAdmin &&
												<>
													<li className="menuitem -subheading"><h4 className="ui-heading -small">Umbrella Administration</h4></li>
													<li className="menuitem"><Link to="/admin/super/organisations" className="link">Organisations</Link></li>
												</>
												}
												{adminMemberships && adminMemberships.length > 1 && (
													<>
														<li className="menuitem -subheading"><h4 className="ui-heading -small">Organisations</h4></li>
														{adminMemberships.map((membership, membershipIndex) => (
															<li key={membershipIndex} className={membership.account.id === currentAccount?.account.id ? 'menuitem -selected' : 'menuitem'}>
																<Link to="/" className="link" onClick={(evt: React.MouseEvent) => {
																	evt.preventDefault()
																	changeMembership(membership) 
																}}>{membership.account.name}</Link>
															</li>
														))}
													</>
												)}
												<li className="menuitem -signout"><a href="/" onClick={doLogout} className="link">Sign out</a> </li>
											</ul>
										</div>
									</ArrowContainer>
								)}
								onClickOutside={() => setPopoverOpen(false)}
							><h3 className="icon-text -account" onClick={() => {
									isPopoverOpen ? setPopoverOpen(false) : setPopoverOpen(true) 
								}}>{currentAccount ? currentAccount.account.name : ''}</h3></Popover> 
						</div>
					}
					{
						hasMenuItems &&
						<label className="menu-toggle js-menuburger" htmlFor="main-nav-menustate" aria-hidden="true">
							<span className="ingredient -bun -top">
								<span className="bar"></span>
							</span>
							<span className="ingredient -patty">
								<span className="bar"></span>
							</span>
							<span className="ingredient -bun -bottom">
								<span className="bar"></span>
							</span>
						</label>
					}
				</div>
			</header>
			{
				props.breadcrumbLink && props.breadcrumbText &&
				<div className="breadcrumb-bar _no-print">
					<div className="width-limit -site">
						<Link to={props.breadcrumbLink} className="icon-text -back">Back to {props.breadcrumbText}</Link>
					</div>
				</div>
			}
		</>
	)
}
